<template>
  <div>
    <template>
      <el-row>
        <el-form
          class="queryParams"
          v-if="searchArr.length"
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          label-width="100px"
          size="mini"
        >
          <el-col
            :span="i.type == 'dateRange' ? 8 : 8"
            v-for="(i, d) in searchArr"
            :key="d"
          >
            <el-form-item :label="i.label + '：'">
              <el-date-picker
                v-if="i.type == 'dateRange'"
                v-model="daterange"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 250px"
              >
              </el-date-picker>
              <el-select
                v-else-if="i.type == 'select'"
                v-model="queryParams[i.prop]"
                placeholder="请选择"
                clearable
                style="width: 250px"
              >
                <el-option
                  v-for="item in i.option"
                  :key="item[i.value || '']"
                  :label="item[i.name || '']"
                  :value="item[i.value || '']"
                >
                </el-option>
              </el-select>
              <el-input
                v-else
                v-model="queryParams[i.prop]"
                style="width: 250px"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button
              type="primary"
              icon="el-icon-search"
              style="margin-left: 20px"
              size="mini"
              @click="handleSearch()"
              >查询</el-button
            >
          </el-col>
        </el-form>
      </el-row>
      <el-table
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :show-header="true"
        :data="tableData"
        style="width: 100%"
        :header-row-style="{
          background:
            'linear-gradient(to right, #55a3ff9c, #2666b1bd, #051e3b)',
          color: '#fff',
        }"
        :header-cell-style="{
          background: 'none',
        }"
        :row-style="{
          background: '#052C4F',
          color: '#fff',
        }"
      >
        <template>
          <div slot="empty" style="padding: 80px 0">
            <img src="@/assets/HeChangShuMei/img/tanchuang/noData.png" alt="" />
            <div style="text-align: center; color: #0c75d7">暂时没有数据~</div>
          </div>
        </template>
        <el-table-column
          v-for="(i, d) in columns"
          :key="d"
          :prop="i.prop"
          :label="i.label"
          :width="i.width"
          :fixed="i.fixed"
          :sortable="i.sortable"
          :show-overflow-tooltip="!i.isTooltip ? true : false"
          :align="i.align ? i.align : 'center'"
        >
          <template slot-scope="scope">
            <template v-if="i.operation && i.operation.length">
              <div
                v-for="(btnItem, btnIndex) in i.operation"
                :key="btnIndex"
                style="display: inline-block; margin: 0px 3px"
              >
                <el-button
                  size="small"
                  :style="{
                    color:
                      btnItem.lable.indexOf('删除') !== -1 ? '#E61B1B' : '',
                    padding: '0px',
                  }"
                  :type="btnItem.type || 'text'"
                  @click.stop="$emit(`${btnItem.funName}`, scope.row)"
                >
                  {{ btnItem.lable }}
                </el-button>
              </div>
            </template>
            <span v-else-if="i.type">
              {{ scope.row.myTypeName }}
            </span>
            <span v-else>
              {{ scope.row[i.prop] || "" }}
            </span>
          </template>
          <!-- <template v-if="i.children && i.children.length">
            <DataTable
              v-for="(colItem, colIndex) in i.children"
              :key="colIndex"
              :column="colItem"
            />
          </template> -->
          <template v-if="i.children && i.children.length">
            <template>
              <el-table-column
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                :fixed="item.fixed"
                :sortable="item.sortable"
                :show-overflow-tooltip="!item.isTooltip ? true : false"
                :align="item.align ? item.align : 'center'"
                v-for="(item, index) in i.children"
                :key="index"
              >
                <template slot-scope="scope">
                  <template v-if="item.operation && item.operation.length">
                    <div
                      v-for="(btnItem, btnIndex) in item.operation"
                      :key="btnIndex"
                      style="display: inline-block; margin: 0px 3px"
                    >
                      <el-button
                        size="small"
                        :style="{
                          color:
                            btnItem.lable.indexOf('删除') !== -1
                              ? '#E61B1B'
                              : '',
                          padding: '0px',
                        }"
                        :type="btnItem.type || 'text'"
                        @click.stop="$emit(`${btnItem.funName}`, scope.row)"
                      >
                        {{ btnItem.lable }}
                      </el-button>
                    </div>
                  </template>
                  <span v-else-if="item.type">
                    {{ scope.row.myTypeName }}
                  </span>
                  <span v-else>
                    {{ scope.row[item.prop] || "" }}
                  </span>
                </template>
              </el-table-column>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        v-if="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
export default {
  name: "DataTable",
  watch: {
    queryParams: {
      handler(newVal) {
        console.log("queryParams", newVal);
        this.pageNum = newVal.pageNum ? newVal.pageNum : 1;
        this.pageSize = newVal.pageSize ? newVal.pageSize : 10;
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    searchArr: {
      type: Array,
      default: () => [],
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 50],
    },
    queryParams: {
      type: Object,
      default: () => {
        return {
          pageNum: 1,
          pageSize: 10,
        };
      },
    },
    maxHeight: {
      type: Number,
      default: 500,
    },
    total: {
      type: Number,
      default: 0,
    },
    searchShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      daterange: [],
      loading: false,
      pageNum: 1,
      pageSize: 10,
    };
  },
  methods: {
    handleSizeChange(val) {
      this.loading = true;
      this.pageSize = val;
      this.$emit("handleSearch", {
        ...this.queryParams,
        pageSize: this.pageSize,
      });
      this.loading = false;
    },
    handleCurrentChange(val) {
      this.loading = true;
      this.pageNum = val;
      this.$emit("handleSearch", {
        ...this.queryParams,
        pageNum: this.pageNum,
      });
      this.loading = false;
    },
    handleSearch() {
      this.loading = true;
      let queryParams = this.queryParams;
      if (this.daterange.length) {
        let daterange = this.searchArr.find((e) => e.type == "dateRange");
        queryParams[daterange.prop[0]] = this.daterange[0];
        queryParams[daterange.prop[1]] = this.daterange[1];
      }
      queryParams.pageNum = 1;
      queryParams.pageSize = 10;
      this.$emit("handleSearch", queryParams);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  padding-bottom: 20px;
}
.queryParams {
  ::v-deep .el-form-item__label {
    color: #01d8f6;
    font-size: 16px;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #1e8fb5 !important;
}
::v-deep .el-table__body-wrapper.is-scrolling-none {
  background: #052c4f;
}
::v-deep .el-table::before {
  height: 0;
}
.pagination {
  margin-top: 10px;
}
</style>
