import Vue from 'vue'
import App from './App.vue'
import router from './router'
import xScaleBox from '@/components/x-ScaleBox/index'
import './style/index.css'
import * as echarts from "echarts"
import 'echarts-gl';

import ElementUI from 'element-ui';
import request from './util/request.js'
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';
import XShuNaoTitleBg from "@/components/x-shuNao-title-bg";
import XTitleBg2 from "@/components/x-title-bg-2";
import XHeaderShuNao from "@/views/shuNaoMain/components/x-header-shuNao";
import XHeaderWebm from "@/views/shuNaoMain/components/x-header-webm";
// import dataV from '@jiaminghi/data-view'
import filter from '@/util/filter'
import vueSeamlessScroll from 'vue-seamless-scroll'


import constant from './const/const.js'
Vue.use(constant);
// vue树
import VueTreeList from 'vue-tree-list'
import xUtil from '@/util/x-util.js'
import DataTable from '@/components/DataTable'
import DataList from '@/components/DataList'

import T from '@/util/tiandituapi' //天地图
import '@/util/ImageOverlay' //天地图 * 图片覆盖物类，用于添加一个只有一张图片的图层，图片会随缩放级别而自适应缩放。

import {
	parseTime,
	resetForm,
	addDateRange,
	selectDictLabel,
	selectDictLabels,
	handleTree,
	getDict,
	formatData
} from "@/util/ruoyi";

// Vue.use(dataV)
Vue.use(ElementUI);
Vue.use(VueTreeList)

// 标题1
Vue.component('XShuNaoTitleBg', XShuNaoTitleBg);
// 标题2
Vue.component('XTitleBg2', XTitleBg2);

Vue.component('XHeaderShuNao', XHeaderShuNao);
Vue.component('XHeaderWebm', XHeaderWebm);

// 无限滚动
Vue.component('vueSeamlessScroll', vueSeamlessScroll);
Vue.component('DataTable', DataTable);
Vue.component('DataList', DataList);


Vue.prototype.$echarts = echarts
Vue.prototype.$request = request
Vue.prototype.$xUtil = xUtil
Vue.prototype.$filter = filter


Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree
Vue.prototype.getDict = getDict
Vue.prototype.formatData = formatData

// 过滤器
for (const key in filter) {
    Vue.filter(key, filter[key]);
}

Vue.config.productionTip = false
Vue.component('xScaleBox', xScaleBox);
new Vue({
    router, render: h => h(App)
}).$mount('#app')
