<template>
  <div class="DataList">
    <div class="content">
      <div class="item" v-for="(i, d) in dataList" :key="d">
        <div
          class="child"
          v-for="(j, x) in i.children"
          :key="x"
          :class="'len' + i.children.length"
        >
          <div class="label" :class="i.isShort ? 'short' : ''">
            {{ j.label }}：
          </div>
          <div class="value" :class="i.isShort ? 'short' : ''">
            <span v-if="!j.children">{{ j.value }}</span>
            <div v-else class="value-content">
              <div class="value-item" v-for="(k, l) in j.children" :key="l">
                <span>{{ k.label }}：</span>
                </span>{{k.value}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.DataList {
  .content {
    // height: calc(100% - 4px);
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-image: linear-gradient(90deg, #015faf 0%, #051e3b 100%) 2 2 2 2;
    border-left: 2px solid #015faf;
    .item {
      display: flex;
      border-bottom: 2px solid;
      border-image: linear-gradient(90deg, #015faf 0%, #051e3b 100%) 2 2 2 2;
      &:nth-last-child(1) {
        border-bottom: none;
      }
      .child {
        width: 100%;
        height: 40px;
        line-height: 40px;
        display: flex;
        text-indent: 1em;
        color: #0691dc;
        &:nth-child(1) {
          .label {
            border-right: 2px solid #015faf;
          }
        }

        .label {
          width: 270px;
          height: 40px;
          // border-right: 2px solid #015faf;

          &.short {
            width: 180px;
          }
        }
        .value {
          width: calc(100% - 270px);
          height: 40px;
          color: #fff;
          .value-content {
            display: flex;
            .value-item {
              width: 50%;
              &:nth-child(1) {
                border-left: 2px solid #015faf;
              }
              span {
                width: calc(100% - 100px);
                &:nth-child(1) {
                  width: 100px;
                }
              }
            }
          }

          &.short {
            width: calc(100% - 180px);
          }
        }
        &.len2 {
          width: 50%;
        }
      }
    }
  }
}
</style>
