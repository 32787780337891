<template>
  <div style="position: relative;z-index: 200;width: 480px;height: 50px">
    <video src="@/assets/video/一级标题.webm" muted autoplay loop
           style="width: 479px;height: 45px;position: absolute;top: -4px;left:0"></video>

    <img src="@/assets/ShuNao/小标题背景.png" style="width: 479px;height: 45px;position: absolute;top: 0;left:0;z-index: 1">

    <div class="douyuFont x-title flex x-text-shadow" style="letter-spacing: 4px">{{ title }}
      <div class="margin-left" v-if="showWenHao" :title="wenHaoTip"><img src="@/assets/common/编组 3.png"
                                                                         style="width: 15px"></div>
    </div>

    <div style="position: absolute;right: 20px;top: 10px;color: #EFF022;letter-spacing: 4px" class="douyuFont" v-if="smallTitle">
      {{ smallTitle }}
    </div>

    <div style="position: absolute;right: 25px;top: 10px;font-size: 14px;letter-spacing:2px;z-index: 201"
         class="cursor flex" v-if="showTimeCheck">
      <div class="x-date-bg" @click="timeClick('7')" :class="checkDate === '7' ? 'x-date-check-bg' : ''">
        <div :class="checkDate === '7' ? 'x-check-num' : ''">近7天</div>
      </div>
      <div class="x-date-bg margin-left" @click="timeClick('30')":class="checkDate === '30' ? 'x-date-check-bg' : ''">
        <div :class="checkDate === '30' ? 'x-check-num' : ''">近30天</div>
      </div>
      <div class="x-date-bg margin-left" @click="timeClick('0')" :class="checkDate === '0' ? 'x-date-check-bg' : ''">
        <div :class="checkDate === '0' ? 'x-check-num' : ''">今年</div>
      </div>
    </div>

    <div style="position: absolute;right: 25px;top: 10px;font-size: 14px;letter-spacing:2px;z-index: 201"
         class="cursor flex" v-if="showSmallSlotTitle">
      <slot name="smallSlotTitle"></slot>
    </div>

    <div
        style="position: absolute;right: 0px;top: 10px;color: rgb(209 249 254);font-size: 14px;letter-spacing:2px;z-index: 201"
        class="cursor" v-if="showMoreList" title="更多">
      <img src="@/assets/common/更多.png" style="width: 20px">
    </div>

  </div>
</template>

<script>
export default {
  name: "x-shuNao-title-bg",
  props: ['title', 'smallTitle', 'showMoreList', 'showWenHao', 'wenHaoTip', 'showTimeCheck', 'showSmallSlotTitle'],
  data: () => {
    return {
      checkDate: '7',
    }
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    timeClick(val) {
      this.$emit('on-dete-click', val)
      this.checkDate = val;
    }
  }
}
</script>

<style scoped>
.x-title {
  font-size: 18px;
  position: absolute;
  left: 40px;
  top: 7px;
  color: white;
  z-index: 201;
}

.x-date-bg{
  width: 60px;
  height: 25px;
}

.x-date-check-bg{
  background: url("~@/assets/common/矩形.png") no-repeat;
  background-size: 100% 100%;
}

.x-check-num {
  font-weight: bolder;
  color: #FFEA7F;
  text-shadow: 0px 0px 29px rgba(255, 234, 127, 0.5400);
  background: linear-gradient(180deg, #FFEA7F 0%, #FFF6B6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
