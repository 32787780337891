export default {

    install(Vue,options){

        Vue.prototype.global = {
            title:'全局',
            isBack: true,
            isAdd:  false,
            river:1,
            lake:2,
            reservoir:3,
        };

    }

 }
